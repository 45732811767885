//$('#reservatie').find('#booking-module-inner').hide();

$('#reserveerBtn').on('click', function(event) {
    event.preventDefault();
    $('#booking-module-inner').show();
    $("#booking-module-inner").removeClass('bounceOutRight');
    $("#booking-module-inner").addClass('bounceInRight');
    event.stopPropagation();
});


//$('.reserveer').find('#booking-module').hide();

$('#reserveerOnlineBtn').on('click', function(event) {
    event.preventDefault();
    $('#booking-module').show();
    $("#booking-module").addClass('slideInDown');
    $("#booking-module").removeClass('slideOutUp');
    event.stopPropagation();

});

$("#booking-module").click(function(e) {
    e.stopPropagation();
});

$("#booking-module-inner").click(function(e) {
    e.stopPropagation();
});

$('.datepicker').click(function(e) {
    e.stopPropagation();
});

$('.close-booker').on('click', function(event) {

    event.preventDefault();
    $(this).parent('#booking-module-inner').addClass('bounceOutRight');
    $(this).parent('#booking-module-inner').removeClass('bounceInRight');

    $(this).parent('#booking-module').addClass('slideOutUp');
    $(this).parent('#booking-module').removeClass('slideInDown');
});


$(document).click(function(){
    $('#booking-module').addClass('slideOutUp');
    $('#booking-module').removeClass('slideInUp');

    $('#booking-module-inner').addClass('bounceOutRight');
    $('#booking-module-inner').removeClass('bounceInRight');
 
});