$('#kamerlinks li').click(function(event) {
	$('#kamerlinks li').removeClass('selected');
	$(this).addClass('selected');
});

$(document).ready(function() {
    $(".inner-nav__list li [href]").each(function() {
    if (this.href == window.location.href) {
    	$('.inner-nav__list li [href]').removeClass('active');
        $(this).addClass("active");
        } 
    });
});

