$('.hamburger').on('click', function(event) {

	event.preventDefault();
	$('#mobile-menu').removeClass('home-nav');
	//$('.home-nav').css('display', 'none');
	$('#mobile-menu').addClass('mobile-nav');
	$('.inner-nav').css('display', 'none');
	//$('.mobile-menu').addClass('mobile-nav');
	$('body').css('position', 'fixed');
});

$('.mobile-menu').on('click', function(event) {
	$('.mobile-menu').removeClass('mobile-nav');
	$('.inner-nav').css('display', 'block');
	$('body').css('position', 'static');
});

$('#closeBtn').on('click',function (event) {
	$('#mobile-menu').removeClass('mobile-nav');
	$('#mobile-menu').addClass('home-nav');
	$('.inner-nav').css('display', 'block');
	$('body').css('position', 'static');
})