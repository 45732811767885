var GoogleMapsLoader = require('google-maps');
GoogleMapsLoader.KEY = 'AIzaSyAdypcv-vuEk21gtIee4G9IYA65VUZYguo';

(function() {
    GoogleMapsLoader.load(function(google) {

        var map;
        var image = {
            url: '/bundles/sitefrontend/img/svg/placeholder.svg',
            scaledSize: new google.maps.Size(50, 50)
        };



        function initialize() {
            var glat = 51.356346,
                glong = 3.308723;

            var mapOptions = {

                zoom: 16,
                scrollwheel: false,
                center: new google.maps.LatLng(glat, glong),
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER
                },
                streetViewControl: false,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.TOP_CENTER,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
                }
            };



            map = new google.maps.Map(document.getElementById('maps'), mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(glat, glong),
                icon: image,
                map: map
            });

        }

        google.maps.event.addDomListener(window, 'load', initialize);
        google.maps.event.addDomListener(window, "resize", function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });

    });
})();
