require("./plugins/bootstrap.js");
require("./plugins/bootstrap-datepicker.js");
require("./plugins/magnific-popup.js");
require("./plugins/hammer.min.js");

require("./modules/fastbooker.js");
require('./modules/form.js');
require('./modules/forms.js');
require('./modules/mobilemenu.js');
require('./modules/scroll.js');
require('./modules/pictures.js');
require('./modules/reserveren.js');
require('./modules/logo.js');
require('./modules/links.js');
require( './plugins/slick.js' );
var bgSlider = $( '.js-slider' );
bgSlider.slick( {
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 4000,
  pauseOnHover: false,
} );
if($("#maps").length){
    require("./views/location.js");
}

$('.linkOut').attr({
  href: 'https://reservations.cubilis.eu/be/approach-hotel-knokke-heist?Language=en-GB',
  target: '_blank'
});
var trigger = $('.room-selector');
var list = $('.rooms-select');

trigger.click(function  ( ) {
    trigger.toggleClass('active');
    list.slideToggle(200);
});

list.click(function() {
    trigger.click();
});

$('.home-nav__list').append('<li class="belons"><a href="tel:003250611130">Bel ons!</a></li>')
