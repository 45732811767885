$(window).scroll(function() {
	var height = $(window).scrollTop();

	if(height>100){
		$('.logo--header-nav, .logo--contact').addClass('logo--header-nav--small');
	}else{
		$('.logo--header-nav, .logo--contact').removeClass('logo--header-nav--small');
	}
	
});
	