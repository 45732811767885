    $('.kamer-fotos').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeContentOnClick: 'true',
        gallery: {
            enabled: true,
            navigateByImgClick: true
        }
    });


 $('.photo-container').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeContentOnClick: 'true',
        gallery: {
            enabled: true,
            navigateByImgClick: true
        }
    });

var mc = new Hammer(document.body);
mc.on('swiperight',function () {
    $(".mfp-arrow-left").magnificPopup('prev');
});

mc.on('swipeleft', function() {
    $('.mfp-arrow-right').magnificPopup('next');
});