(function(d) {
    var mL = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'];

    d.prototype.getLongMonth = d.getLongMonth = function getLongMonth(inMonth) {
        return gM.call(this, inMonth, mL);
    };

    d.prototype.getShortMonth = d.getShortMonth = function getShortMonth(inMonth) {
        return gM.call(this, inMonth, mS);
    };

    function gM(inMonth, arr) {
        var m;

        if (this instanceof d) {
            m = this.getMonth();
        } else if (typeof inMonth !== 'undefined') {
            m = parseInt(inMonth, 10) - 1; // Subtract 1 to start January at zero
        }

        return arr[m];
    }
})(Date);

(function($) {

    //Header Fastbooker
    var d = new Date(new Date().getTime());
    var t = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    var today = d.getDate() + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear();
    var tomorrow = t.getDate() + "/" + ('0' + (t.getMonth() + 1)).slice(-2) + "/" + t.getFullYear();


    var arrival = $('#arrival');
    var departure = $('#departure');

    var arrivalAlt = $('#arrival-alt');
    var departureAlt = $('#departure-alt');

    arrival.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: 'd',
        weekStart: 1,
        orientation: 'bottom'
    }).on('changeDate', function(e) {
        departure.focus();
        var selectedDate = arrival.datepicker('getDate');
        var nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
        departure.datepicker('setDate', nextDay);
        departure.datepicker('setStartDate', nextDay);
    });

    departure.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: tomorrow,
        weekStart: 1,
        orientation: 'bottom'
    }).on("changeDate", function(e) {
        var selectedDeparture = departure.datepicker('getDate');
    });



    arrivalAlt.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: 'd',
        weekStart: 1,
        orientation: 'bottom'
    }).on('changeDate', function(e) {
        departureAlt.focus();
        var selectedDate = arrivalAlt.datepicker('getDate');


        var nextDay = new Date(selectedDate);
        nextDay.setDate(nextDay.getDate() + 1);
        departureAlt.datepicker('setDate', nextDay);
        departureAlt.datepicker('setStartDate', nextDay);
    });

    departureAlt.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        startDate: tomorrow,
        weekStart: 1,
        orientation: 'bottom'
    }).on("changeDate", function(e) {

        var selectedDeparture = departureAlt.datepicker('getDate');


    });

    var btn = $('#btn-fastbooker');
    var btnAlt = $('#btn-fastbooker-alt');

    btn.click(function(e) {
        var selectedDate = arrival.datepicker('getDate');
        var arrivalValue = arrival.val();
        arrivalValue = arrivalValue.split('/');
        arrivalValue = arrivalValue[2] + '-' + arrivalValue[1] + '-' + arrivalValue[0];


        var selectedDeparture = departure.datepicker('getDate');
        var departureValue = departure.val();
        departureValue = departureValue.split('/');
        departureValue = departureValue[2] + '-' + departureValue[1] + '-' + departureValue[0];

        e.preventDefault(); 
        var href = "https://reservations.cubilis.eu/approach-hotel-knokke-heist/Rooms/Select?Arrival="+arrivalValue+"&Departure="+departureValue+"";
  //      var href = "https://booking.cubilis.eu/check_availability.aspx?logisid=102&startdatum=" + arrivalValue + "&einddatum=" + departureValue + "";
        window.open(href, '_blank');

    });

    btnAlt.click(function(e) {
        var selectedDate = arrivalAlt.datepicker('getDate');
        var arrivalAltValue = arrivalAlt.val();
        arrivalAltValue = arrivalAltValue.split('/');
        arrivalAltValue = arrivalAltValue[2] + '-' + arrivalAltValue[1] + '-' + arrivalAltValue[0];


        var selectedDeparture = departureAlt.datepicker('getDate');
        var departureAltValue = departureAlt.val();
        departureAltValue = departureAltValue.split('/');
        departureAltValue = departureAltValue[2] + '-' + departureAltValue[1] + '-' + departureAltValue[0];

        e.preventDefault();
      var href = "https://reservations.cubilis.eu/approach-hotel-knokke-heist/Rooms/Select?Arrival="+arrivalAltValue+"&Departure="+departureAltValue+"";
      //  var href = "https://booking.cubilis.eu/check_availability.aspx?logisid=102&startdatum=" + arrivalAltValue + "&einddatum=" + departureAltValue + "";
        window.open(href, '_blank');

    });


    function getCubilisLang(lang) {
        var cubilisLang = 'en-GB';
        if (lang === 'nl') {
            cubilisLang = 'nl-NL';
        } else if (lang === 'fr') {
            cubilisLang = 'fr-FR';
        } else if (lang === 'de') {
            cubilisLang = 'de-DE';
        }
        return cubilisLang;
    }


})(jQuery);
